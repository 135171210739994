@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500&display=swap');

* {    
  --main-bg-color: #EFEFEF;
  
  --light-font-weight: 300;
  --regular-font-weight: 400;
  --medium-font-weight: 500;

  font-family: 'Raleway', sans-serif;
  font-weight: var(--regular-font-weight);
  font-size: xx-large;

  margin: 0 ;
  padding: 0 ;
}

.App {
  background-color: var(--main-bg-color);
  width: 100%;
  position: absolute;
  top: 0px;
}

a {
  all: unset;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}